$bootstrap-icons-font-src: url("../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff2") format("woff2"), url("../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");

@import "~bootstrap-icons/font/bootstrap-icons.scss";

// Set font family
$font-family-base: Times, serif;
$rtg-yellow: #f9d35e;
$rtg-black: #000000;
$rtg-purple: #7f59bf;
$rtg-purple-dimmed: #463267;
$rtg-blue: #030d61;

// Overrides
@import "mixins/font-size.mixin";
@import "mixins/breakpoint.mixin";

// Import Bootstrap's SCSS
@import '~bootstrap/scss/bootstrap';

// Overrides
@import "mixins/container.mixin";

.rtg-main {
  background: linear-gradient($rtg-purple, $rtg-purple-dimmed);
  padding: 0;
}

.rtg-content {
  background: $rtg-purple;
}

.rtg-inner--img {
  max-width: 100%;
}

.rtg-quote--h2,
.rtg-quote--quotes,
.rtg-quote--container {
  font-size: 12px;
  font-weight: bold;
}

.rtg-quote--h2 {
  text-decoration: underline;
  margin-bottom: 1em;
  font-size: 14px;
}

.rtg-quote--container {

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5em;

  color: $rtg-black;
  background: $rtg-yellow;

  @include media-breakpoint-up(md) {
    max-height: 325px;
    margin-top: -338px;
    font-size: 9px;
  }

  a {
    color: $rtg-black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  }
}

.rtg-inner {
  padding: 0;
}

.rtg-quote--quotes {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 0.5em;
    position: relative;
  }

  // Accessibility
  a {
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.rtg-bio-inner {
  color: white;
  background-color: #030d61;
  padding: 1em;
}

.rtg-bio-inner--h2,
.rtg-bio-inner--p {
  //
}

.rtg-order {
  color: white;
  display: flex;
  flex-direction: column;
  padding: 2em;
  position: relative;

  .rtg-order--audio {
    text-align: center;
    margin-bottom: 2em;
  }

  .rtg-order--h2 {
    margin-bottom: 1em;
  }

  .rtg-order--h3 {
    font-weight: bold;
    font-size: 14px;
  }

  .rtg-order--img {
    max-width: 70%;
    box-shadow: 0px 0px 9px 8px #DCBA72;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .rtg-order--link {
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    padding: 1em;
    margin-bottom: 1em;
    border: 1px dotted white;
    display: inline-block;
    color: white;

    &:hover {
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  }

  .rtg-order--sticky {
    top: 2em;
    position: sticky;
  }

}

.rtg-excerpt {
  color: white;
  padding: 2em;

  .rtg-excerpt-back {
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    padding: 1em;
    margin-bottom: 1em;
    border: 1px dotted white;
    display: inline-block;
    color: white;

    &:hover {
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  }
}