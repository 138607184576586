// Define custom container max widths
$container-max-widths: (
        sm: 720px,  // Small viewport
        md: 720px,  // Medium viewport
        lg: 720px,  // Large viewport
        xl: 720px // Extra-large viewport
);

// Loop through the breakpoints and set the max-width for .container
@each $breakpoint, $width in $container-max-widths {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    .container {
      max-width: $width;
    }
  }
}